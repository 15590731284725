.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  height: 100% !important;
  display: flex;
  width: 20% !important;
  justify-content: center;
}

.css-wf16b5 {
  height: 100% !important;
  display: flex;
  width: 20% !important;
  justify-content: center;
}

.css-bshv44-MuiButtonBase-root-MuiListItem-root {
  display: flex !important;
  justify-content: center !important;
  margin-top: 30px;
  background: antiquewhite;
  display: flex;
  align-items: center;
}

.css-w0pj6f::after {
  background: linear-gradient(90deg, pink, #000000);
  bottom: 0;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

.css-qs2q9j::after {
  background: linear-gradient(90deg, pink, #000000);
  bottom: 0;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

.css-bshv44-MuiButtonBase-root-MuiListItem-root::after {
  background: linear-gradient(90deg, pink, #000000);
  bottom: 0;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

.css-h4y409-MuiList-root {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 50px !important;
  padding-bottom: 8px;
  display: flex !important;
  gap: 15px;
  flex-direction: column !important;
}

.modal-header {
  border-bottom: none !important;
}

tbody:nth-child(odd) {
  background-color: #d3d3d3 !important;
}

.table>tbody,
.table>thead {
  border-radius: 4px;
  margin-top: 2px !important;
}

tbody:nth-child(2n) {
  background-color: #e0e0e0 !important;
}

.tables {
  border-collapse: separate !important;
  text-align: center !important;
  width: 100%;
}

a {
  color: black;
  text-decoration: none !important;
  cursor: pointer;
}

.tableBody {
  width: 100% !important;
}

.react-datepicker {
  font-family: Arial, sans-serif;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 999;
}

.react-datepicker__header {
  background-color: #05653F;
  color: #fff;
  z-index: 999;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.react-datepicker__day-name {
  width: 30px;
  text-align: center;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-around;
}

.react-datepicker__day {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: 2px;
  border-radius: 50%;
}

.react-datepicker__day:hover {
  background-color: #05653F;
  color: #fff;
  cursor: pointer;
}

.react-datepicker__day--selected {
  background-color: #05653F;
  color: #fff;
}

.react-datepicker__day--keyboard-selected {
  background-color: #05653F;
  color: #fff;
}

.react-datepicker__header {

  justify-content: space-between;
  align-items: center;
  background-color: #05653F;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px 10px;
}

.react-datepicker__navigation--previous {
  background-color: rgb(196, 194, 194);
  border: 1px solid var(--green);
  font-size: 16px;
  height: 30px;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}

.react-datepicker__navigation--next {
  background-color: rgb(196, 194, 194);
  border: 1px solid var(--green);
  font-size: 16px;
  height: 30px;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}

.react-datepicker__aria-live {
  display: none;
}

.react-time-picker__inputGroup__input {
  min-width: none;
}

.react-time-picker__clockIcon:hover {
  color: #007bff;
}

.react-time-picker__wrapper {
  border: none !important;
}

.form-control-lg {
  font-size: 16px !important;
  border-radius: 2px solid black !important;
}

.option {
  background-color: #000000 !important;
}

.carousel .control-dots .dot {
  background: #05653F !important;
}

.carousel .slide {
  text-align: start !important;
}

.carousel img {
  width: auto !important;
}

.carousel-container {
  height: 100vh;
  overflow: hidden;
}

.carousel-item {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.carousel.carousel-slider .control-arrow {
  top: 0;
  color: black;
  font-size: 26px;
  bottom: auto !important;
  margin-top: 0;
  padding: 5px;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background: cadetblue !important;
}

@media only screen and (min-width: 1030px) and (max-width: 1800px) {
  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    width: 50% !important;
  }
}

@media only screen and (min-width: 100px) and (max-width: 880px) {
  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    width: 50% !important;
  }

  .css-wf16b5 {
    width: 50% !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1024px) {
  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    width: 40% !important;
  }

  .css-wf16b5 {
    width: 40% !important;
  }
}

@media only screen and (min-width: 150px) and (max-width: 335px) {
  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    width: 60% !important;
  }

  .css-wf16b5 {
    width: 60% !important;
  }
}