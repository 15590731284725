@media only screen and (min-width: 600px) and (max-width: 1280px) {
  .welcome {
    font-size: 44px;
  }
  .ModalContent{
    width: 50% !important;
  }
  .welcome-container-main {
    width: 75%;
  }
  .createTourSignatureModal{
    margin-top: 40px;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 2480px) {
  .welcome {
    font-size: 44px;
  }

  .welcome-container-main {
    width: 55%;
  }

}

@media only screen and (min-width: 701px) and (max-width: 1024px) {
  .main-container {
    width: 65% !important;
    /* margin-top: 50px; */
}
.ValidationStyle{
  margin-top: 90px !important;
}
  .ModalContent{
    width: 50% !important;
  }
  .details {
    justify-content: center;
    align-items: center;
  }
  .createTourSignatureModal{
    margin-top: 40px;
  }
  .modal-content {
    width: 45% !important;
  }
}
@media only screen and (min-width: 100px) and (max-width: 768px) {
  .main-container {
    width: 95% ;
    /* margin-top: 50px !important; */
}
.ValidationStyle{
  margin-top: 90px !important;
}
.table-responsive {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  .textHead{
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 100px) and (max-width: 600px) {
  .background {
    display: block !important;

  }
  .inputWidthNoH {
    width: 100% !important;
    margin-top: 5px;
  }
  .iconCheck{
    height: 20px;
  }
  .createTourSignatureModal{
    margin-top: 65px;
  }
  .ModalContent{
    width: 70% !important;
  }
.welcome-container-main {
  padding: 10px;
}
  .drop-login a {
    width: 35% !important;
  }

  .headerTitile {
    font-size: 16px !important;
    margin-left: 107px !important

  }
  .textHead{
    margin-left: 0px !important;
  }
  .break-container {
    width: 95% !important;
  }
.buttonContainer{
  margin-top: 10px;
}
.error{
  width: 80%;
}
.textHead{
/* margin-right: 18px; */
}
.image-container {
  height: 40px;
}

  .image {
    width: 100%;
    height: 40vh !important;
  }

  .loginContainer {
    width: 100%;
    height: 60vh !important;
  }

  .imgIcon {
    width: 100%;
    height: 100%;
  }

  .welcome {
    font-size: 34px;
  }

  .inputDate {
    height: 90px !important;
  }

  .trip-container {
    display: block !important;
  }

  .main-container {
    width: 95% ;
    /* margin-top: 50px !important; */
}

  .welcomeBack {
    font-size: 32px;
  }

  .welcome-container-main {
    width: 95%;
  }

  .main {
    width: 90%;
  }

  .calendar-day-names {
    display: block !important;
  }

  .calendar-week {
    display: block !important;
  }

  .trip-card {
    display: block !important;
  }

  .details {
    justify-content: center;
    align-items: center;
  }

  .background-image {
    width: 100%;
  }

  .yellow-ribbon {
    display: none;
  }

  .pauseContainer {
    display: block !important;
  }

  .inputbox {
    display: block !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;

  }

  .textcolor {
    font-size: 18px !important;
  }

  .inputWidth {
    width: 100% !important;
    /* height: 95px; */
  }

  .inputGagbage {
    width: 40% !important;
  }

  .inputDate {
    width: 100% !important;
  }

  .right-section {
    margin-top: 10px !important;
    margin-bottom: 12px !important;
  }

  .startContainer {
    display: block !important;
  }

  .right-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .sidebar {
    width: 25px !important;
    height: 25px !important;
  }

  .delectIcon {
    bottom: 16px !important;
  }

  .calendar-header {
    margin-bottom: 10px !important;
  }

  .inputIcon {
    justify-content: end !important;
  }

  .delectIcon {
    bottom: 9px !important;
    left: 65px !important;
  }

  .calendar-header {
    margin-bottom: 10px !important;
  }

  .inputIcon {
    justify-content: end !important;
    flex-direction: row !important;
  }

  .nextButton {
    padding-bottom: 0px !important;
    margin-top: 10px !important;
  }

  .modal-content {
    width: 91% !important;
  }

  .admin {
    /* width: fit-content !important; */
  }
 .profilePicture{
    width: 30px !important;
    height: 30px !important;
    margin: 4px;
  }
  .garbage {
    width: 50% !important;
  }
.sidebar-icon{
  font-size: 15px;
    margin-right: 1px;
}
  .selecterInput {
    flex-direction: column !important;
  }

  .inputselect {
    justify-content: center !important;
    margin-top: 8px !important;
  }

  .details {
    width: 100% !important;
    height: auto !important;
  }

  .StepperContainer-0-2-1 {
    padding: 9px !important;
  }

  .StepContainer-0-2-2 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .StepButton-d6-0-2-12 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d2-0-2-8 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d10-0-2-22 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d14-0-2-30 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d18-0-2-38 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d22-0-2-46 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d26-0-2-54 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d30-0-2-62 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d34-0-2-70 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d38-0-2-78 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .dropdown-content {
    top: 31px !important;
    right: -19px !important;
  }

  .image-container {
    /* margin-left: 20px !important; */
  }

  .image-container img {
    height: 21px !important;
    width: min-content !important;
  }

  .items {
    font-size: 10px !important;
  }

  .mainHeader {
    padding-left: 20px;
    padding-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }



  .user-language-container {
    width: 97% !important;
  }
}

@media only screen and (min-width: 150px) and (max-width: 335px) {
  .calendar-day-names {
    display: block !important;
  }
  .ModalContent{
    width: 90%;
  }
  .calendar-week {
    display: block !important;
  }

  .mainHeader {
    display: block !important;
  }

  .modal-content {
    width: 60% !important;
  }

  .admin {
    /* width: fit-content !important; */
  }
  .sidebar-icon{
  font-size: 15px;
    margin-right: 1px;
}
  .profilePicture{
    width: 30px !important;
    height: 30px !important;
    margin: 4px;
  }
}

@media only screen and (min-width: 755px) and (max-width: 920px) {
  .modal {
    top: -100px !important;
  }
  .ModalContent{
    width: 90%;
  }
  .createTourSignatureModal{
    margin-top: 40px;
  }
  .details {
    height: auto !important;
  }
}

@media only screen and (min-width: 80px) and (max-width: 300px) {
  .StepperContainer-0-2-1 {
    padding: 12px !important;
  }
  .ModalContent{
    width: 90%;
  }
  .createTourSignatureModal{
    margin-top: 40px;
  }
  .StepContainer-0-2-2 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .StepButton-d6-0-2-12 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d2-0-2-8 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d10-0-2-22 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d14-0-2-30 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d18-0-2-38 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d22-0-2-46 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d26-0-2-54 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d30-0-2-62 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d34-0-2-70 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .StepButton-d38-0-2-78 {
    width: 25px !important;
    height: 25px !important;
    font-size: 12px !important;
  }

  .image-container {
    margin-left: 0px !important;
  }

  .image-container img {
    height: 20px !important;
    width: min-content !important;
    padding-left: 0px !important;
    padding-right: 5px !important;
  }

  .headerTitile {
    font-size: 10px !important;
    margin-left: 40px !important
  }

  .main_header {
    padding-right: 10px !important;
  }

  .dropdown-content {
    right: 0 !important;
  }

  .dropMain {
    margin-right: 0px !important;
  }

  .greeting {
    font-size: 25px !important;
  }

  .instruction {
    font-size: 16px !important;
  }

  .drop-login a {
    width: 30% !important;
  }
}

@media (min-width: 375px) {
  .login_main_div {
    height: 80%;
  }
}