body {
  margin: 0;
  /* font-family: 'Mukta', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    font-family:  Calibri  !important;

}

code {
      font-family:  Calibri, !important;

}
