:root {
  --green: #05653F;
  --white: #fff;
  --black: black;
  --orange: #F6821F:;
  --av:#05653F
}

.carousel-root {
  height: 88% !important;
}

.carousel {
  height: 100%;
  margin: 0;
  padding: 0;
}

.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  transition: height 0.15s ease-in;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: 100% !important;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
}

.slider-wrapper.axis-horizontal {
  overflow-y: auto;
}

.inputHeading {
  padding-left: 3px !important;
}

.scrollable-container {
  max-height: 80vh;
  overflow-y: auto;
}

.signaText {
  font-size: 18px;
  text-align: start;
  font-weight: 500;
}

.mainView {
  height: 100%;
  max-height: 100%;
  width: 100%;
}

.imgIcon {
  width: 100%;
  /* height: '' */
}

.image {
  background-repeat: no-repeat;
  background-size: contain;
  width: 60%;
  height: 100%;
  /* padding: 10px; */
}

.background {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  max-width: 100%;
}

.loginContainer {
  background-color: #07754d;
  height: 100%;
  width: 40%;
  position: relative;
}

.welcome {
  font-size: 48px;
  color: var(--white);
  text-align: center;
  font-family: 'Playfair Display';
}

.gap {
  gap: 20px;
}

.input {
  border: 1px solid var(--white);
  border-radius: 6px;
  padding: 10px 15px;
  width: 70% !important;
}

.input:focus-visible {
  outline: none;
}

.search {
  border: 1px solid gray;
  border-radius: 6px !important;
  color: black;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  max-width: auto;
  width: auto;
}

.search:focus-visible {
  outline: none;
}

.buttonContainer {
  border: 1px solid var(--white);
  border-radius: 6px !important;
  color: black;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  max-width: auto;
  width: auto;
}

.buttonContainer.hoverButton:hover {
  background-image: linear-gradient(-25deg, #616161 20%, #05653F 80%);
  color: white;
}

.buttonContainer.no-hover:hover {
  background-color: #17b169;
}

.breakPoint {
  background-color: var(--white);
  border: 2px solid var(--green);
  border-radius: 6px !important;

  font-size: 18px;
  height: 49px;

  padding-left: 20px;
  padding-right: 20px;
  width: 200px;
}

.buttonDash {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.submitIcon {
  width: 35px;
  height: 35px;
box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 50%;
  background-color: 'white';
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: center;
}

.plueIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trip-container {
  position: relative;
  width: 100%;
  background-color: var(--green);
  padding: 30px;
}

.calendar-container {
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.calendar-header h2 {
  margin: 0;
  color: white;
}

.calendar-days {
  display: flex;
  flex-direction: column;
}

.calendar-day-names {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  color: var(--white);
  font-weight: 400;
}

.calendar-day-name {
  flex: 1;
  color: var(--white);
  font-weight: 400;
}

.calendar-week {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  cursor: pointer;
}

.calendar-day {
  flex: 1;
  padding: 10px;
  border: 1px solid var(--white);
  color: var(--white);
  font-weight: 500;
}

.calendar-day.disabled {
  color: '#d83068';
  cursor: pointer;
}

.calendar-footer {
  margin-top: 20px;
}

.calendar-footer .calendar-days {
  display: flex;
  justify-content: space-between;
}

.calendar-footer .calendar-day {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
}

.nextmonth {
  background-color: var(--white);
  border: 1px solid var(--white);
  border-radius: 6px !important;
  height: 25px;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  color: black;
}

.plusIcon {
  height: 30px;
  width: 30px;
  background-color: whitesmoke;
  border-radius: 50%;
  padding: 5px;
}

.clock1 {
  gap: 7px !important;
}

.clock {
  gap: 6px;
}

h1,
h2,
h3,
h4,
p,
h5,
h6,
ul,
li {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
}

.main {
  box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 50%;
}

.trip-card {
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
}

.left-section {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
}

.background-image {
  width: 35%;
  height: 180px;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.details {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 50px;
  text-align: center;
}

.right-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  justify-content: end;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}

.yellow-ribbon {
  position: absolute;
  top: 52px;
  right: -9px;
  width: 76px;
  height: 5px;
  transform: rotate(45deg);
  transform-origin: bottom right;
  border-radius: 0 4px 0 0;
  z-index: 1;
  display: flex;
}

.delectIcon {
  position: absolute;
  bottom: 10px;
  right: 0;
}

.angleIcon {
  cursor: pointer;
  height: 40px;
  width: 20px;
}

.icon {
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.iconRight {
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.scrollMain {
  overflow-y: auto;
}

.time-text {
  margin-bottom: 20px;
}

.right-partition {
  flex: 1;
  margin-left: 20px;
}

textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
}

.clockIcon {
  margin-right: 4px;
}

.iconBack {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.text {
  font-size: 18px;
}

.main-container {
  width: 40%;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.1);
  background-color: rgb(253, 252, 252);
  /* height: auto; */
  margin: auto;
}

.break-container {
  width: 60% !important;
  border-radius: 5px !important;
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.1) !important;
  background-color: rgb(253, 252, 252);
  height: auto;
  margin: auto;
}

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  padding-top: 10px;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
}

.welcome-container-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.1);
  background-color: 'rgb(253, 252, 252)';
}

.backIcon {
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.welcomeIcon {
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.height {
  height: 100%;
  min-height: 100%;
}

.inputbox {
  gap: 14px;
}

.inputWidth {
  width: 50%;
  height: 80px;
}

.inputGagbage {
  width: 13% !important;
}

.inputDate {
  width: 50%;
  height: 84px;
}

.textcolor {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  color: var(--white);
}

.stepper-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.step {
  width: 30px;
  height: 30px;
  border: 2px solid var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50%;
}

.step.active {
  background-color: var(--black);
  color: var(--white);
  border-color: var(--black);
}

.box-container {
  border: 2px solid var(--black);
  padding: 20px;
}

.input-row {
  display: flex;
  margin-bottom: 10px;
}

.divider {
  border-bottom: 1px solid var(--black);
  width: 100%;
  margin-top: 5px;
}

.crossIcon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
}

.modal-content {
  margin: auto;
  padding: 15px;
  background-color: whitesmoke;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
}

.ModalContent {
  margin: auto;
  /* padding: 15px; */
  background-color: white;
  width: 30% !important;
  height: auto;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  gap: 40px;
}

.modal-header h2 {
  margin: 0;
  text-align: center;
}

.blurred {
  filter: blur(5px);
  pointer-events: none;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.datepickerbg {
  color: 'black';
}

.modal-header .close {
  cursor: pointer;
  color: #999;
  align-self: flex-start;
}

.close {
  color: var(--green);
  font-size: 28px;
  font-weight: bold;
  float: left !important;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: var(--green) !important;
  text-decoration: none;
  cursor: pointer;
}

.modal-content h2 {
  margin-top: 0;
  text-align: center;
}

.modal-content label {
  display: block;
}

.modal-content input[type='text'],
.modal-content input[type='password'] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal-content button[type='submit'] {
  background-color: var(--green);
  color: var(--white);
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button[type='submit']:hover {
  background-image: linear-gradient(-25deg, #616161 20%, #05653F 80%);
  color: white;
}
.inputWidthNoH{
  width: 50%;
}
.inputform {
  padding: 6px;
  border: 1.5px solid black;
  border-radius: 4px;
  color: black;
  font-size: 17px;
}
.selectInput{
   width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 11px;
  border: 1.5px solid black;
  border-radius: 4px;
  color: black;
  font-size: 16px;
}
.selectInput option {
  white-space: normal;
}
.textFont {
  font-size: 18px;
}

.inputform:focus-visible {
  outline: none;
}

.inputformDate {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: black !important;
}

.inputformDate:focus-visible {
  outline: none;
}

.alert {
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  flex-direction: column;
  gap: 10px;
}

.selecte {
  background-color: var(--green);
  color: white;
  padding: 8px;
}

.spinner {
  text-align: center;
}

.spinner>div {
  width: 22px;
  height: 22px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes bounce {

  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.text-center {
  text-align: center;
}

.register {
  color: var(--white);
  text-decoration: underline;
  text-align: center;
}

.sidebarIcon {
  width: 3%;
  height: 15%;
}

.sidebar {
  width: 40px;
  height: 40px;
}

.sidebar-toggle {
  position: absolute;
  top: 15%;
  left: 3%;
}

.sidebar-profile {
  position: absolute;
  top: 15%;
  right: 3%;
}

.sidebar-icon {
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.sidebar-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
}

.sidebar-heading {
  text-align: center;
}

.sidebar-item {
  display: flex;
  align-items: center;
}

.sidebar-item:last-child {
  border-bottom: none;
}

.buttonLogot {
  background-color: var(--green);
  border: 1px solid var(--green);
  color: black;
  font-size: 16px;
  font-weight: 500;
  height: 60px;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  max-width: '100%';
  width: '100%';
  color: white;
}

.arrowIcon {
  height: 8%;
  width: 4%;
  cursor: pointer;
}

.tableHeading {
  background: var(--green) !important;
  color: var(--white) !important;
  font-size: 16px;
  border-radius: '0px 6px 0px 6px ' !important;
}

.action {
  padding: 0.5rem;
  margin-left: 30px;
}

.table>thead,
.table>tbody {
  border-radius: 4px;
  margin-top: 2px;
  font-family: Helvetica;
}

.table>tbody {
  font-size: 15px;
  font-weight: 400;
}

.upload-label {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.upload-label img {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.upload-icon {
  font-size: 60px;
  cursor: pointer;
}

.profilePicture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 10px;
}

.animation-item {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.items {
  font-size: 16px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownLanug {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 15px;
  font-size: 16px;
  border: none;
}

.dropdown-content {
  display: flex;
  position: absolute;
  background-color: var(--white);
  min-width: max-content;
  z-index: 1;
  flex-direction: column;
  cursor: pointer;
  top: 50px;
  border: 2px solid lightblue;
}

.dropdown a::after {
  content: '';
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  background: linear-gradient(90deg, pink, #000000);
  height: 1px;
}

.dropdown-content a {
  display: flex;
  padding: 8px 16px;
  text-decoration: none;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 40%;
}

.drop-login a {
  display: flex;
  padding: 8px 16px;
  text-decoration: none;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 15%;
}

.plus-icon-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #05653F;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.choose-container {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #05653F;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  color: var(--white);
  /* font-size: 16px; */
}

.choose-container:hover {
  transform: scale(1.1);
}

.backIcon:hover {
  transform: scale(1.1);
}

.plus-icon-container:hover {
  transform: scale(1.1);
}

.flagImage {
  height: 40px;
  width: 70px;

}

.pause-icon-container {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #05653F;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.pause-icon-container:hover {
  transform: scale(1.1);
}

.trip-details-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.trip-details-box {
  width: 100%;
  background-color: rgb(200, 199, 199);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.clock-background {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 150px;
}

.welcomingText {
  text-align: center;
}

.welcomeText {
  color: #05653F;
  /* font-style: italic; */
}

.login-content {
  width: 100%;
  max-width: 400px;
}

.welcomeBack {
  font-size: 40px;
  color: #05653F;
  font-weight: bold;
  overflow-wrap: break-word;
  /* width: 70%; */
}



.stepper {
  height: 35px;
  width: 35px;
  border-radius: 6px;
  margin: 5px;
  color: 'white';
  font-size: 16px;
}

.userHeading {
  background: var(--green);
  border-radius: 10px 10px 0px 0px;
  font-weight: 700;
  text-align: center;
  color: white;
  position: relative;
  padding: 16px;
  text-align: center;
}

.unSelected {
  background-color: rgb(221, 236, 241);
  padding: 8px;
}

.back-arrow {
  position: absolute;
  left: 11px;
  top: 7px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.close-icon {
  cursor: pointer;
  height: 25px;
  width: 25px;
  position: absolute;
  top: 2px;
  right: 6px;
}

.iconSave {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.iconSave:hover {
  animation: fadeIn 0.2s ease-in-out;
}

.iconCheck {
  width: 50px;
  height: 30px;
  cursor: pointer;
}
.delectIconP{
   width: 25px;
  height: 25px;
  cursor: pointer;
}
.yellow-ribbon-detail {
  position: absolute;
  top: 66px;
  right: 1px;
  width: 98px;
  height: 4px;
  transform: rotate(45deg);
  transform-origin: bottom right;
  border-radius: 0 4px 0 0;
  z-index: 1;
  display: flex;
}

.modal-body {
  margin-bottom: 20px;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.custom-select {
  position: relative;
  width: 100%;
}

.custom-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: 1.5px solid black;
  padding: 8px 30px 8px 10px;
  box-sizing: border-box;
  width: 100%;
}

.selectedGreen {
  color: green;
}

.selectedBlack {
  color: black;
}

.custom-select select option {
  color: black !important;
}

.custom-select .custom-arrow {
  position: absolute;
  top: 49%;
  right: 6px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #666;
}

.custom-select select:focus {
  outline: none;
}

.error {
  color: red;
  margin-left: 16%;
  font-size: 14px;
}

.checkbox {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid black;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dropMain {
  margin-right: 22px;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.mainHeader {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.main_header {
  /* padding-left: 7px; */
  /* padding-right: 40px; */
  /* padding-top: 12px;
  padding-bottom: 12px; */
  padding: 12px;
  background-color: #07754d;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonAdd {
  background-color: var(--white);
  border: 2px solid green;
  border-radius: 6px !important;
  color: black;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  height: 49px;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  max-width: auto;
  width: auto;
  width: 150px;
}

.error-message {
  color: red;
  font-size: 14px;
}

.checkbox-container {
  position: relative;
  display: inline-block;
  margin-right:12px
}

.checkbox-image {
  width: 25px;
  height: 25px;
}

.check-image {
  position: absolute;
  top: 5px;
  left: 6px;
  width: 14px;
  height: 14px;
}

.menu {
  display: block;
  margin: 0 auto;
  position: relative;
}

.menu>span>a {
  display: block;
  text-align: center;
  text-decoration: none;
}

.menu ul {
  background: rgb(246, 244, 244);
  height: 0;
  left: -100px;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  top: 41px;
  min-width: 168px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu ul a {
  color: #000;
  display: block;
  padding: 5px 20px;
  z-index: 1;
}

.image-container {
  width: min-content;
  height: 50px;
  display: flex;
  align-items: center;
  clip-path: (0 5px 100% 75% round 15% 0);
  justify-content: end;
  background-color: #07754d;
  /* margin-left: 30px; */

}

/* .image-container img {
  height: 20px;
  width: 150px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
} */

.trip-details-container {
  padding: 10px;
}

.break-times-container {
  margin-bottom: 20px;
}

.inputbox {
  margin-top: 20px;
}

.welcome-container {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.welcome-container p {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.truncate-single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.garbage_Type {
  height: 80px !important;
}

.greeting {
  font-size: 30px;
  color: #333;
  padding: 10px;
  font-style: italic;
  font-family: auto;
  font-weight: 600;
}

.language-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instruction {
  height: 45px;
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
}

.language_bg {
  position: relative;
  background-image: url('../assets/imagesPng/langbg.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.language_bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.user-language-container {
  position: relative;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 500px;
}

.ValidationStyle {
  margin-top: 58px;

}

.ValidationStyle1 {
  margin-top: 30px;

}

.login_main_div {
  height: 90%;
}

.stepcount {
  position: absolute;
  width: 100%;
  /* left: 38%; */
  /* top: 10%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.textHead {
  width: 100%;
  text-align: center;
  margin-left: 160px;
}

.table-responsive {
  width: 100%;
  min-width: 300px;
  border-collapse: collapse;
  margin: 0 auto;
}
.table-responsive thead th,
.table-responsive tbody td {
  padding: 8px;
  text-align: center;
  border: 1px solid rgb(130, 130, 130);
}

.image-preview-grid {
  display: flex;
  width:'100%';
  flex-wrap: wrap;
  /* grid-template-columns: repeat(auto-fill, minmax(80px, 1fr)); */
  gap: 10px;
  margin-top: 10px;
  flex-direction: row;
}

.image-preview-item {
  position: relative;
  border: 2px solid #F6821F;
  border-radius: 8px;
  overflow: hidden;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.remove-image-btn {
  position: absolute;
  top: 2px;
  right: 2px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.remove-image-btn:hover {
  background: #F6821F;
}
.circle-icon{
  background-color: #F6821F;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
.modalInputss{
   height: 445px;
   width: 445px;
 margin: auto;
  padding: 10px;
}
